@import "theme.less";

.drawer {
  & > div > div {
    background-color: @primary-background;
  }
}

.iconRed {
  & > svg {
    fill: @error-color;
  }
}
